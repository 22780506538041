import React from 'react';
import { Button, Card, Image } from 'semantic-ui-react'
import Survey from '../Assets/Dashboard-Survey.png'
import Report from '../Assets/Dashboard-Report.png'
import NewSurvey from '../Assets/Dashboard-NewSurvey.png'

const ReportCard = (props) => {

    var surveyState =""
    var surveycompletionDate = ""
    var cardImage=""

    if (props.userData["report"+props.name]) {
        surveyState = "Completed"
        surveycompletionDate = props.userData["report"+props.name].metadata.date
        cardImage = Report
    }else if (props.userData["responses"+props.name]) {
        surveyState = "Started"
        cardImage = Survey
    }else{
        surveyState = "Not Started"
        cardImage = NewSurvey
    }


    const handleViewReport = () => {
        props.handleViewReport(props.name)
    }

    const handleRestartSurvey = () => {
        props.handleRestartSurvey(props.name)
    }

    const handleContinueSurvey = () => {
        props.handleContinueSurvey(props.name)
    }

    const handleStartSurvey =() => {
        props.handleStartSurvey(props.name)
    }
    return (
<Card>
      <Card.Content>
      <Image
          floated='right'
          size='mini'
          src={cardImage}
        />
        <Card.Header>Survey</Card.Header>
        
        <Card.Description>
          Status: <strong>{surveyState}</strong>
        </Card.Description>
        {surveyState === "Completed" && <Card.Description>Date Completed <strong>{surveycompletionDate}</strong></Card.Description>}
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
            {surveyState === "Completed" && <Button style={{ backgroundColor: "#dd9933", color: "white" }} onClick={handleViewReport}>View Report</Button>}
            
            {surveyState === "Started" && 
            <><Button style={{ backgroundColor: "#dd9933", color: "white", marginRight: "1px" }} onClick={handleRestartSurvey}>Restart</Button> 
            <Button style={{ backgroundColor: "#dd9933", color: "white" }} onClick={handleContinueSurvey}>Continue</Button>
            </>
            }
            
            {surveyState === "Not Started" && <Button style={{ backgroundColor: "#dd9933", color: "white" }} disabled={props.disabled} onClick={handleStartSurvey}>Start</Button>}

        </div>
      </Card.Content>
    </Card>
    )
}

export default ReportCard