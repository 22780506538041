import firebase from 'firebase/app';
import 'firebase/database';
import "firebase/auth";
import  'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyD1_VSkTbtRtMdXy-AFuuuSrf55D9bMwEY",
  authDomain: "theloftnetwork-fb628.firebaseapp.com",
  projectId: "theloftnetwork-fb628",
  storageBucket: "theloftnetwork-fb628.appspot.com",
  messagingSenderId: "630141135761",
  appId: "1:630141135761:web:68560230772e4eaccf0830",
  databaseURL: "https://theloftnetwork-fb628-default-rtdb.firebaseio.com/"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebaseApp.database();
const auth = firebaseApp.auth()
const myfunctions = firebaseApp.functions();


export { database, auth, myfunctions };