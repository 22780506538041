import React, { useState, useEffect } from 'react';
import { database, auth, myfunctions } from '../firebase';
import '../App.css';
import Navbar from '../Components/Navbar';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../Components/AuthContext';
import { Grid, Dimmer, Loader, Progress } from 'semantic-ui-react'
import Footer from '../Components/Footer';
import SurveyResponses from '../Components/Survey-Responses';
import SurveyBreak from '../Components/Survey-SectionBreak';
import { useParams } from 'react-router-dom';

function Survey() {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [questions, setQuestions] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [waiting, setwaiting] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
 
    if (!user) {
      // User is not authenticated, navigate to the login page
      console.log("no user");
      navigate('/login');
      return;
    }
    const userRef = database.ref('/users/' + user.uid);

    // Fetch questions from the database or call CreateUserSurveyQuestions if needed
    userRef.on('value', async (snapshot) => {
      const data = snapshot.val();

      if (data && data.RegistrationInfo.accountType === "admin") {
        console.log("Admin User")
        navigate('/admin');
      }

      if (data && data[`responses${id}`] && data[`responses${id}`].length > 0) {
        setQuestions(data[`responses${id}`]);

        for (let i = 0; i < data[`responses${id}`].length; i++) {
          if (data[`responses${id}`][i].Response == '""') {
            setCurrentQuestionIndex(i)
            break;
          }
        }

      } 
    });

  }, [database, setQuestions, auth, navigate, setCurrentQuestionIndex]);

  const nextQuestion = () => {

    var finalAnswer = selectedAnswer;
    if (questions[currentQuestionIndex].Section === "End" || questions[currentQuestionIndex].Section === "Break" || questions[currentQuestionIndex].Section === "Start") {
      finalAnswer ="Answered"
    }

    const updatedQuestions = [...questions]; // Copy the original array
    updatedQuestions[currentQuestionIndex] = { ...updatedQuestions[currentQuestionIndex], Response: finalAnswer }; // Update the response value

    // Set the state with the updated array
    setQuestions(updatedQuestions);

    const userRef = database.ref(`/users/${user.uid}/responses${id}/${currentQuestionIndex}/Response`);
    userRef.set(finalAnswer);

    if (currentQuestionIndex < questions.length - 1) {
      const nextQuestionIndex = currentQuestionIndex + 1
      setCurrentQuestionIndex(nextQuestionIndex);
      setSelectedAnswer(questions[currentQuestionIndex + 1].Response);
    }
  };

  const runReport = async () => {
    console.log('Running report...');
    setwaiting(true);
    try {
      const reportFunction = myfunctions.httpsCallable('runreport');
      const messageData = {
        userID: user.uid,
        surveyID: id
      };
      console.log("created data")
      console.log(messageData);
      const result = await reportFunction(messageData);
      console.log('Result:', result);
      setwaiting(false);
      navigate(`/report/${id}`);
    } catch (error) {
      console.error('Error running the report:', error);
    }
  };

  const previousQuestion = () => {
    // console.log(currentQuestionIndex)
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedAnswer(questions[currentQuestionIndex - 1].Response);
    }
  };

  const handleAnswerChange = (answer) => {
    setSelectedAnswer(answer);

  };


  // console.log("questions", questions);
  return (
    <Grid className="survey-grid">
    <Dimmer active={waiting}>
      <Loader >Working on your report. This might take a minute.</Loader>
    </Dimmer>

      <Grid.Row>
        <Navbar signOut={true} />
      </Grid.Row>
      
      {questions ? (
 
        <Grid.Row>
          <div className="survey-container question-fade">
          <Grid.Row>
          <Progress percent={((currentQuestionIndex + 1) / questions.length) * 100} indicating />  
        </Grid.Row>
            <h2 className="survey-section-header">
              {questions[currentQuestionIndex].Section}
            </h2>

            {/* Only display the "Question" title when it is not a "Start" or "Break" section */}
            {questions[currentQuestionIndex].Section !== 'Start' &&
              questions[currentQuestionIndex].Section !== 'Break' &&
              questions[currentQuestionIndex].Section !== 'End'? (
              <p className="survey-question-header">
                Question {currentQuestionIndex}
              </p>
            ) : null}
            <p className="survey-question-text">
              {questions[currentQuestionIndex].Description}
            </p>

            {/* Only display the "responses"  when it is not a "Start" or "Break" or "End" section */}
            {questions[currentQuestionIndex].Section !== 'Start' &&
              questions[currentQuestionIndex].Section !== 'Break' &&
              questions[currentQuestionIndex].Section !== 'End' ? (

              <SurveyResponses
                previousQuestion={previousQuestion}
                nextQuestion={nextQuestion}
                currentQuestionIndex={currentQuestionIndex}
                selectedAnswer={selectedAnswer}
                handleAnswerChange={handleAnswerChange} 
                />

            ) : (
              <SurveyBreak 
              nextQuestion={nextQuestion} 
              section={questions[currentQuestionIndex].Section}
              runReport={runReport}
              />
            )}




          </div>

        </Grid.Row>
      ) : null}

      <Grid.Row>
        <Footer />
      </Grid.Row>
    </Grid>
  );
}

export default Survey