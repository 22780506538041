import React, { useEffect } from 'react';
import PaymentOptionsCard from '../Components/Payment-OptionsCard';
import { Grid } from 'semantic-ui-react'

import Consultant from '../Assets/Consultant.png';
import OnlineSurvey from '../Assets/Online-Survey.png';
import Premium from '../Assets/Premium.png';

import NavBar from '../Components/Navbar';
import Footer from '../Components/Footer';
import AppVariables from './AppVariables';
import '../App.css';

function Payment() {
   
  return (
    <div>
      <NavBar signOut={true} />
      <div className='payment-screen'>

        <Grid centered>

          <Grid.Column width={4}>
            <PaymentOptionsCard
              imgsrc={OnlineSurvey}
              name = {AppVariables.products.basic.name}
              id = {AppVariables.products.basic.id}
              price= {AppVariables.products.basic.price}
              description={AppVariables.products.basic.description}
               />
          </Grid.Column>

          <Grid.Column width={4}>
            <PaymentOptionsCard
              imgsrc={Consultant}
              name = {AppVariables.products.plus.name}
              id = {AppVariables.products.plus.id}
              price= {AppVariables.products.plus.price}
              description={AppVariables.products.plus.description}
               />
          </Grid.Column>

          <Grid.Column width={4}>
            <PaymentOptionsCard
              imgsrc={Premium}
              name = {AppVariables.products.premium.name}
              id = {AppVariables.products.premium.id}
              price= {AppVariables.products.premium.price}
              description={AppVariables.products.premium.description}
              />
          </Grid.Column>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default Payment;