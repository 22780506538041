import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Grid, Menu } from 'semantic-ui-react'
import ForgotPassword from "../Components/Login_ForgotPassword";
import RegisterSection from "../Components/Login_RegisterSection";
import LoginSection from "../Components/Login_LoginSection";
import WelcomeText from "../Components/Login_WelcomeText";
import "../App.css";


function Login() {
  const [loginRegister, setLoginRegister] = useState("Register");
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

  return (
    <div>

      <ForgotPassword setOpenModal={setOpenForgotPasswordModal} openModal={openForgotPasswordModal} />

      <Grid className="login-page" divided>
        <Grid.Row className="navbar">
          <NavBar signOut={false} />
        </Grid.Row>

        <Grid.Column width='8' className="login-page-intro-text-box">
          <Grid.Row >
            <WelcomeText />
          </Grid.Row>

        </Grid.Column>


        <Grid.Column width='6'>

          <Menu tabular>
            <Menu.Item
              name='Register'
              active={loginRegister === 'Register'}
              onClick={(e, { name }) => setLoginRegister(name)}
              style={{ backgroundColor: "#dd9933", color: "white" }}
            />
            <Menu.Item
              name='Login'
              active={loginRegister === 'Login'}
              onClick={(e, { name }) => setLoginRegister(name)}
              style={{ backgroundColor: "#dd9933", color: "white" }}
            />
          </Menu>
          {loginRegister === 'Login' && <LoginSection setOpenForgotPasswordModal={setOpenForgotPasswordModal}/>}
          {loginRegister === 'Register' && <RegisterSection />}
        </Grid.Column>
        <Grid.Row className="footer">
          <Footer />
        </Grid.Row>

      </Grid>

    </div>
  );
}

export default Login;
