
import React, { useState } from 'react';
import { Button, Input, Modal, Icon } from 'semantic-ui-react'
import { auth } from '../firebase';

const ForgotPassword = ({ setOpenModal, openModal }) => {
    const [email, setEmail] = useState('');
    const [resetSuccess, setResetSuccess] = useState('');
    const [message, setMessage] = useState('');

    const handleResetPassword = async () => {
        try {
            await auth.sendPasswordResetEmail(email);
            setResetSuccess(true);
        } catch (error) {
            setResetSuccess(false);
            setMessage('Error: ' + error.message);
        }
    };

    return (

        <Modal
            onClose={() => setOpenModal(false)}
            onOpen={() => setOpenModal(true)}
            open={openModal}
        >
            <Modal.Header>Forgot Password</Modal.Header>

            <Modal.Content>
                <p>Enter your email address to receive a password reset link.</p>
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {resetSuccess ? <p><Icon name="checkmark" color="green" ></Icon>Check your email for a password reset link.</p> : <p>{message}</p>}
            </Modal.Content>

            <Modal.Actions>
                <Button color='black'
                    onClick={() => {
                        setOpenModal(false)
                        setEmail('')
                        setResetSuccess("")
                        setMessage('')
                    }
                    }>
                    Close
                </Button>
                {!resetSuccess &&
                    <Button
                        content="Reset Password"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={handleResetPassword}
                        positive
                    />
                }

            </Modal.Actions>
        </Modal>
    );
};

export default ForgotPassword;