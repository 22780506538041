import React from 'react'
import { database } from '../firebase';
import { Button, Card, Icon, Image } from 'semantic-ui-react'
import { useAuth } from './AuthContext';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../App.css'

const PaymentOptionsCard = (props) => {
  const { user } = useAuth();
  const { successParam } = useParams();
  const navigate = useNavigate();

  if (successParam && user) {
    // Handle success case

    const successResponses = ['basic', 'plus', 'premium']
    if (successResponses.includes(successParam)) {
      const userRef = database.ref('/users/' + user.uid + "/RegistrationInfo/");
      userRef.update({ payment: successParam })
      navigate('/dashboard');

    }else{
      navigate('/payment')
    }
  }

  const handleClick = async () => {
    try {
      const response = await fetch(
        "https://create-checkout-payment-7s7idnmoka-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productID: props.id,
          }),
        }
      );

      const data = await response.json();

      console.log("Data:", data);

      if (response.ok) {
        // Redirect to the checkout URL
        console.log(data.redirect_url);
        window.location.href = data.redirect_url
      } else {
        console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <Card fluid style={{ height: '100%', margin: '20px 0px' }}>
      <Card.Content>
      <Image src={props.imgsrc} size="small" floated="right" />
      
        <Card.Header textAlign='center'>{props.name}</Card.Header>

        <Card.Description>
          {props.description}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='payment-price'>
            <Icon name='dollar sign' />
            {props.price}
          </div>
          <Button
            onClick={handleClick} color='blue'>Lets Go</Button>

        </div>
      </Card.Content>
    </Card>
  )

}

export default PaymentOptionsCard