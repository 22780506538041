import React from "react";
import { auth, database } from "../firebase";



const RegisterUser = async (firstName, lastName, email, accountType, signUpPackage, password, multipleRegistration, selfRegistration, passwordRetype) => {
  var regestrationError = ""

  const isEmailValid = (email) => {
    // Basic email format validation using a regular expression
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };


  if (!firstName) {
    if (multipleRegistration) {
      regestrationError = `-- ${email} Please enter your first Name. `;
      return (regestrationError)
    } else {
      regestrationError = `Please enter your first Name. `;
      return (regestrationError)
    }
  }

  if (!lastName) {
    if (multipleRegistration) {
      regestrationError = `-- ${email} Please Enter your Last Name. `;
      return (regestrationError)
    } else {
      regestrationError = `Please Enter your Last Name. `;
      return (regestrationError)
    }
  }

  if (!isEmailValid(email)) {
    if (multipleRegistration) {
      regestrationError = `-- ${email} is an invalid email format. Please enter a valid email address.`;
      return (regestrationError)
    } else {
      regestrationError = `Please enter a valid email address. `;
      return (regestrationError)
    }
  }

  if (accountType == "student" && !signUpPackage) {
    if (multipleRegistration) {
      regestrationError = `-- ${email} Please select a package.`;
      return (regestrationError)
    } else {
      regestrationError = `Please select a package.`;
      return (regestrationError)
    }
  }

  console.log(selfRegistration)
  if (selfRegistration) {
    if (!password || password.lenght < 8) {
      regestrationError = `Password needs to be at least 8 charachters long`;
      return (regestrationError)
    }

    if (password != passwordRetype){
      regestrationError = `Retype password does not match`;
      return (regestrationError)
    }
  }else{
    password = "CareerMapping2024"
  }

  console.log("regestrationError", regestrationError)
  if (!regestrationError) {
    console.log("no error")
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      var userId = user.uid; // Set the userId to the user.uid;

    } catch (error) {
      console.error(error);
      regestrationError = `-- ${email} registration failed. ${error.message} `
      return (regestrationError)
    }
    try {
      const now = new Date();
      const isoDate = now.toISOString();
      const userdata = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        registrationDate: isoDate,
        payment: signUpPackage,
        accountType: accountType
      }
      const userRef = database.ref(`users/${userId}/RegistrationInfo`);
      await userRef.set(userdata);
      if (multipleRegistration) {
        regestrationError = `-- ${email} successfully created. `
      } else {
        regestrationError = null
      }
      
    } catch (error) {
      regestrationError = `-- ${email} registration failed. Please try again. ${error.message} `
      return (regestrationError)
    }
  }


  return (regestrationError)

}



export default RegisterUser;