import React, { useState } from 'react'
import RegisterUser from './RegisterUser';
import { useNavigate } from "react-router-dom";
import {Grid, Button, Input} from 'semantic-ui-react'
import "../App.css";

const RegisterSection =()=>{
    const [userRegistrationDetails, setUserRegistrationDetails] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    var multipleRegistration=false
    var selfRegistration=true
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        var errorMsg = await RegisterUser(
            userRegistrationDetails.firstName,
            userRegistrationDetails.lastName, 
            userRegistrationDetails.email, 
            userRegistrationDetails.accountType="studnet", 
            userRegistrationDetails.signUpPackage="TBD", 
            userRegistrationDetails.password,
            multipleRegistration,
            selfRegistration,
            userRegistrationDetails.passwordRetype
            );

        if (!errorMsg) {
          navigate("/payment");
        }else{
          setErrorMessage(errorMsg);
        }
      };

    return(
      <Grid.Row className="login-page-inputs-containers">

        <Grid.Row>
        <h2 className="login-page-headers">Register</h2>
        </Grid.Row>

        <Grid.Row>
          <Input
          className="login-page-inputs"
          type="First Name"
          placeholder="First Name"
          value={userRegistrationDetails.firstName}
          onChange={(e) => setUserRegistrationDetails({ ...userRegistrationDetails, firstName: e.target.value })}
        />
        </Grid.Row>

        <Grid.Row>
          <Input
          className="login-page-inputs"
          type="Last Name"
          placeholder="Last Name"
          value={userRegistrationDetails.lastName}
          onChange={(e) => setUserRegistrationDetails({ ...userRegistrationDetails, lastName: e.target.value })}
        />
        </Grid.Row>

        <Grid.Row>
        <Input
          className="login-page-inputs"
          type="email"
          placeholder="Email"
          value={userRegistrationDetails.email}
          onChange={(e) => setUserRegistrationDetails({ ...userRegistrationDetails, email: e.target.value })}
        />
        </Grid.Row>

        <Grid.Row>
          <Input
          className="login-page-inputs"
          type="password"
          placeholder="Password"
          value={userRegistrationDetails.password}
          onChange={(e) => setUserRegistrationDetails({ ...userRegistrationDetails, password: e.target.value })}
        />
        </Grid.Row>

        <Grid.Row>
          <Input
          className="login-page-inputs"
          type="password"
          placeholder="Type Password Again"
          value={userRegistrationDetails.passwordRetype}
          onChange={(e) => setUserRegistrationDetails({ ...userRegistrationDetails, passwordRetype: e.target.value })}
        />
        </Grid.Row>

        <Grid.Row className="login-page-buttons">
          <Button onClick={handleRegister} color="blue">Register</Button>
        </Grid.Row>
        <Grid.Row>
        {errorMessage && <p className="login-page-error">{errorMessage}</p>}

        </Grid.Row>

      </Grid.Row>
    )
  }

  export default RegisterSection
    ;