import React from 'react';
import { Radio, Button } from 'semantic-ui-react'

const SurveyResponses = ({ previousQuestion, nextQuestion, currentQuestionIndex, selectedAnswer, handleAnswerChange }) => {
  return (
    <div>
      <div className="survey-responses">
        <div>
          <Radio
            type="radio"
            value="very interested"
            checked={selectedAnswer === "very interested"}
            onChange={() => handleAnswerChange("very interested")}
          />
          Very Interested
        </div>
        <br />

        <div>
          <Radio
            type="radio"
            value="interested"
            checked={selectedAnswer === "interested"}
            onChange={() => handleAnswerChange("interested")}
          />
          Interested
        </div>
        <br />

        <div>
          <Radio
            type="radio"
            value="not sure"
            checked={selectedAnswer === "not sure"}
            onChange={() => handleAnswerChange("not sure")}
          />
          Not Sure
        </div>
        <br />
        <div>
          <Radio
            type="radio"
            value="no way"
            checked={selectedAnswer === "no way"}
            onChange={() => handleAnswerChange("no way")}
          />
          No Way
        </div>
        <br />

      </div>
      <div className="survey-next-button">

        <Button
          style={{backgroundColor:"#dd9933", color:"white"}}
          onClick={previousQuestion}
          disabled={currentQuestionIndex === 0}>
          Back
        </Button>

        <Button
          style={{backgroundColor:"#dd9933", color:"white"}}
          onClick={nextQuestion}
          disabled={selectedAnswer === '""' || selectedAnswer == null}>
          Next Question
        </Button>
      </div>
    </div>
  )
}

export default SurveyResponses;