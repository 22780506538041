import '../App.css'
import AT_logo from '../Assets/AT_logo_small.png'
function Footer(props) { 
  
{/* <div style={{ position: 'relative', minHeight: '15vh' }}> */}
    return (   
      
  <footer className="footer" style={props.style}>
    <p style={{ display: 'inline' }}>
      Powered By <img src={AT_logo} height='30' alt="AT Logo" style={{ verticalAlign: 'middle' }} />
    </p>
  </footer>


    )
}

export default Footer