import React from "react";
import { Grid,  Container, Divider } from "semantic-ui-react";
import Footer from "./Footer";
import "../App.css";

function AdminReport({reportData}) {


  const report_header = () => {
    return (
      <Container className="report-header-container">
        <h3 className="report-header-title">Occupation Assessment Report</h3>
        {reportData?.metadata ? (
          <h3 className="report-header-name">
            Report for: {reportData.metadata.userFirstName} {reportData.metadata.userLastName}
          </h3>
        ) : (
          <p>Loading...</p>
        )}
        <h3 className="report-header-date">Report Date: {reportData?.metadata.date}</h3>

        <Divider horizontal style={{ color: "white" }}>Next Steps</Divider>
      <p className="report-header-text">- <a className="link" href="https://liveourfuturetoday.com/free-consultation/" target="_blank" rel="noopener noreferrer">Click Here</a>- to Schedule your coaching Session (if Purchased)</p>
      <p className="report-header-text">- <a className="link" href="https://www.onetonline.org/find/all/" target="_blank" rel="noopener noreferrer">Click Here</a>- to use the ONET codes in your report to find out more details about your top occupations</p>
      <p className="report-header-text">- Develop and Activate your Plan!</p>
      <p className="report-header-disclaimer">Disclaimer: The LOFT Network does not guarantee a complete listing of all viable occupations that could be suitable for each client. This is due to the many subjective things that need to be contemplated by the client along with the accuracy of each client’s input. The L.O.F.T. Network’s model has filtered through thousands of data points to identify and prioritize those occupations that have the most elements associated with your noted preferences. </p>
      </Container>

    )
  }

  const report_body = (data) => {

    return (
      <div>

        <Container className="report-body-header-container">
          <p className="report-body-header-title"> <strong>Occupation Title: </strong> {data?.Title}</p>
          <p className="report-body-header-text"><strong>Description: </strong>{data?.Description}</p>
          <p className="report-body-header-text"><strong>ONET Code:</strong> {data?.ONETCode}</p>

        </Container>

        <Container >
          <p className="report-section-header pos">Your Top "Liked" Preferences associated with this occupation</p>
          <Grid columns='3' divided>
            <Grid.Row>
              <Grid.Column>
                {data.Top1 ? (<p> 1. {data?.Top1}</p>) : null}
              </Grid.Column>
              <Grid.Column>
                {data.Top2 ? (<p> 2. {data?.Top2}</p>) : null}
              </Grid.Column>
              <Grid.Column>
                {data.Top3 ? (<p> 3. {data?.Top3}</p>) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {data.Top4 ? (<p> 4. {data?.Top4}</p>) : null}
              </Grid.Column>
              <Grid.Column>
                {data.Top5 ? (<p> 5. {data?.Top5}</p>) : null}
              </Grid.Column>
              <Grid.Column>
                {data.Top6 ? (<p> 6. {data?.Top6}</p>) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* {data.Bottom1 ? (
            <div>
              <p className="report-section-header neg">Your Top “Disliked or Not Sure” Preferences associated with this occupation</p>
              <Grid columns='3' divided>
                <Grid.Row>
                  <Grid.Column>
                    {data.Bottom1 ? (<p> 1. {data?.Bottom1}</p>) : null}
                  </Grid.Column>
                  <Grid.Column>
                    {data.Bottom2 ? (<p> 2. {data?.Bottom2}</p>) : null}
                  </Grid.Column>
                  <Grid.Column>
                    {data.Bottom3 ? (<p> 3. {data?.Bottom3}</p>) : null}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {data.Bottom4 ? (<p> 4. {data?.Bottom4}</p>) : null}
                  </Grid.Column>
                  <Grid.Column>
                    {data.Bottom5 ? (<p> 5. {data?.Bottom5}</p>) : null}
                  </Grid.Column>
                  <Grid.Column>
                    {data.Bottom6 ? (<p> 6. {data?.Bottom6}</p>) : null}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          ) : null} */}

        </Container>
      </div>
    )
  }


  return (
    <>
    <Container>

      {report_header()}
      {console.log(reportData)}
      {reportData && reportData.metadata && (
      <React.Fragment>
        {Object.values(reportData).slice(0, 30).map((data, index) => (
          <React.Fragment key={index}>
            {report_body(data)}
          </React.Fragment>
        ))}
      </React.Fragment>
    )}
    
    </Container>
    <Footer style={{backgroundColor: "#dd9933", minHeight: "10vh", marginTop: "70px"}}/>
    </>
  );
}

export default AdminReport;