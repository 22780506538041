import React from 'react';
import {Radio, Button} from 'semantic-ui-react'

const SurveyBreak = ({ nextQuestion, section, runReport,  }) => {
  const handleClick = () => {
    if (section !== "End") {
      console.log("Setting selected answer to 'Finished'...");
      nextQuestion();
    } else {
      console.log("Running report in Survey Break...");
      runReport();
    }
  };

  return (
    <div className="survey-next-button">
      <Button 
        style={{backgroundColor:"#dd9933", color:"white"}} 
        onClick={handleClick}>
        Lets Go
      </Button>
    </div>
  );
};

export default SurveyBreak;