import React, { useEffect, useState } from "react";
import { database, myfunctions } from "../firebase";
import { Navigate } from "react-router-dom";
import NavBar from "../Components/Navbar";
import { Button, Container, Modal, Input, Dropdown, Grid, Divider, Icon } from "semantic-ui-react";
import RegisterUser from "../Components/RegisterUser";
import SortedTable from "../Components/Admin-SortedTable";
import { useAuth } from '../Components/AuthContext';
import * as XLSX from 'xlsx';

function Admin() {
    const [creatreSingleAccountModalOpen, setCreatreSingleAccountModalOpen] = useState(false);
    const [creatreBulkAccountModalOpen, setCreatreBulkAccountModalOpen] = useState(false);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [accountEmail, setAccountEmail] = useState("");
    const [accountType, setAccountType] = useState("student");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [signUpPackage, setSignUpPackage] = useState("");
    const [adminSecurityPhrase, setAdminSecurityPhrase] = useState("");
    const [response, setResponse] = useState("");
    const [userData, setUserData] = useState([]);
    const [bulkAccountFile, setBulkAccountFile] = useState(null);
    const [bulkRegistrationResponse, setBulkRegirstrationResponse] = useState("");
    const [showCreateAccountButton, setShowCreateAccountButton] = useState(true);
    const [accountEmailforPasswordReset, setAccountEmailforPasswordReset] = useState("");
    const [resetPasswordResponse, setResetPasswordResponse] = useState("");


    const accountTypeOptions = [
        { key: 'admin', text: 'Admin', value: 'admin' },
        { key: 'student', text: 'Student', value: 'student' },
    ]

    const signUpPackageOptions = [
        { key: 'basic', text: 'Basic', value: 'basic' },
        { key: 'plus', text: 'Plus', value: 'plus' },
        { key: 'premium', text: 'Premium', value: 'premium' },

    ]
    const { user } = useAuth();

    useEffect(() => {

        if (!user) {
            // User is not authenticated, redirect them to the login page
            console.log("User is not authenticated");
            return <Navigate to="/login" />;
        }
        const userRef = database.ref('/users/');
        userRef.on('value', async (snapshot) => {
            const data = snapshot.val();

            if (data) {
                setUserData(data);
            }
        })

    }, [])



    const createNewAccount = async () => {

        var paymantPackage = ""

        if (accountType == "admin" && adminSecurityPhrase.toLowerCase() == "create admin account") {
            paymantPackage = "NA"
            var regestrationError = await RegisterUser(firstName, lastName, accountEmail, accountType, paymantPackage)
        } else {
            regestrationError = "please type in the phrase 'Create admin account'"
        }

        if (accountType == "student") {
            regestrationError = ""
            var regestrationError = await RegisterUser(firstName, lastName, accountEmail, accountType, signUpPackage)
        }

        if (!regestrationError) {
            setResponse("Successfully created account");
        }
        else { setResponse(regestrationError) }



    }



    const bulkCreateUsers = async () => {
        setBulkRegirstrationResponse("")
        const promises = bulkAccountFile.map(async (row) => {
            const accountEmail = row[0];
            const firstName = row[1];
            const lastName = row[2];
            const signUpPackage = row[3];
            const accountType = "student";

            if (accountEmail != "Email (Must be a valied email)") {

                try {
                    const dbResponse = await RegisterUser(firstName, lastName, accountEmail, accountType, signUpPackage);
                    return dbResponse;
                } catch (error) {
                    return "Error creating bulk student ";
                }
            }
        });

        // Wait for all promises to resolve
        const responses = await Promise.all(promises);

        // Update state with the responses
        setBulkRegirstrationResponse([...responses]);

    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const dataArray = XLSX.utils.sheet_to_json(sheet, {
                    header: 1,
                    range: 1,
                    blankrows: false, // Exclude blank rows
                    defval: null, // Treat blank cells as null
                }).filter((row) => Object.values(row).some((cell) => cell !== null));

                // Set the converted array in state
                setBulkAccountFile(dataArray);

                setBulkRegirstrationResponse("Found " + (dataArray.length - 1) + " accounts to be created");

                // console.log("dataArray", dataArray);

            };

            reader.readAsBinaryString(file);
        }
    }



    const createSingleAccountModal = () => {

        return (
            <Modal
                onClose={() => setCreatreSingleAccountModalOpen(false)}
                onOpen={() => setCreatreSingleAccountModalOpen(true)}
                open={creatreSingleAccountModalOpen}
                trigger={<Button style={{ backgroundColor: "#dd9933", color: "white" }} onClick={() => setCreatreSingleAccountModalOpen(true)}>Create New Account</Button>}
            >
                <Modal.Header>Create New Account</Modal.Header>
                <Modal.Content>
                    <h4>Please fill all below fields</h4>
                </Modal.Content>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Dropdown
                                    placeholder='Account Type'
                                    value={accountType}
                                    onChange={(e, data) => { setAccountType(data.value) }}
                                    options={accountTypeOptions} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                {accountType == "student" && <Dropdown placeholder='Package' onChange={(e, data) => setSignUpPackage(data.value)} options={signUpPackageOptions} />}
                                {accountType == "admin" && <Input label='Type "Create admin account"' placeholder='Type Here' onChange={(e, data) => setAdminSecurityPhrase(data.value)} />}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Input placeholder='Account Email' onChange={(e, data) => setAccountEmail(data.value)} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Input placeholder='First Name' onChange={(e, data) => setFirstName(data.value)} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Input placeholder='Last Name' onChange={(e, data) => setLastName(data.value)} />
                            </Grid.Column>
                        </Grid.Row>
                        <p className="warning">{response}</p>
                    </Grid>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => {
                        setAccountType("student");
                        setSignUpPackage("");
                        setAccountEmail("");
                        setFirstName("");
                        setLastName("");
                        setAdminSecurityPhrase("");
                        setResponse("");
                        setCreatreSingleAccountModalOpen(false)
                        setShowCreateAccountButton(true)
                    }}>
                        Close
                    </Button>
                    {showCreateAccountButton &&
                        <Button
                            content="Create Account"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => { createNewAccount() }}
                            positive
                        />
                    }
                </Modal.Actions>
            </Modal>
        )
    }

    const createBulkAccountsModal = () => {

        return (
            <Modal
                onClose={() => setCreatreBulkAccountModalOpen(false)}
                onOpen={() => setCreatreBulkAccountModalOpen(true)}
                open={creatreBulkAccountModalOpen}
                trigger={<Button style={{ backgroundColor: "#dd9933", color: "white" }} onClick={() => setCreatreBulkAccountModalOpen(true)}>Create Multiple Accounts</Button>}
            >
                <Modal.Header>Create Multiple Accounts</Modal.Header>
                <Modal.Content>
                    <div>
                        <a href="https://firebasestorage.googleapis.com/v0/b/theloftnetwork-fb628.appspot.com/o/Static%2FLOFT%20Bulk%20Upload%20Example.xlsx?alt=media&token=1535b03e-e1b4-49e4-b86d-1f733ac434fa"><Icon name="file excel outline" > Template</Icon></a>
                        <br />
                        <br />
                    </div>

                    <Input type="file" className="form-control" onChange={handleFileUpload} />
                    <br />
                    {bulkRegistrationResponse && <p className="warning">{bulkRegistrationResponse}</p>}

                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => {
                        setBulkRegirstrationResponse("");
                        setCreatreBulkAccountModalOpen(false)
                        setShowCreateAccountButton(true)
                    }}>
                        Close
                    </Button>
                    {showCreateAccountButton &&
                        <Button
                            content="Create Accounts"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => {
                                bulkCreateUsers()
                                setShowCreateAccountButton(false)
                            }}
                            positive
                        />
                    }
                </Modal.Actions>
            </Modal>
        )
    }

    const resetPasswordModal = () => {

        const resetPassword = async() => {

            if (accountEmailforPasswordReset != "") {
                const passwordResetFunction = myfunctions.httpsCallable('resetPassword')
                const messageData = {
                    user_email: accountEmailforPasswordReset,
                  };
                  const result = await passwordResetFunction(messageData)
                  console.log("result.data.message", result.data.message)
                  setResetPasswordResponse(result.data)

                  
            }
        }

        return (
            <Modal
                onClose={() => setResetPasswordModalOpen(false)}
                onOpen={() => setResetPasswordModalOpen(true)}
                open={resetPasswordModalOpen}
                trigger={<Button style={{ backgroundColor: "#dd9933", color: "white" }} onClick={() => setResetPasswordModalOpen(true)}>Reset Account Password</Button>}
            >
                <Modal.Header>Reset Account Password</Modal.Header>
                <Modal.Content>
                    <Input
                        placeholder="Account Email"
                        onChange={(e, data) => setAccountEmailforPasswordReset(data.value)}>
                    </Input>


                </Modal.Content>

                <Modal.Actions>
                    {resetPasswordResponse && <p>{resetPasswordResponse.message}</p>}
                    <Button onClick={() => {
                        setAccountEmailforPasswordReset("");
                        setResetPasswordModalOpen(false)
                    }}>
                        Close
                    </Button>

                    <Button
                        content="Reset Password"
                        labelPosition='right'
                        icon='redo'
                        onClick={() => {
                            resetPassword()
                            
                        }}
                        positive
                    />

                </Modal.Actions>
            </Modal>
        )
    }


    return (
        <div>
            <NavBar signOut={true} />

            <Divider></Divider>

            <Container>
                <h2>Admin Actions</h2>
                {createSingleAccountModal()}
                {createBulkAccountsModal()}
                {resetPasswordModal()}
            </Container>
            <Divider horizontal>
                Student List
            </Divider>

            <SortedTable data={userData} />

        </div>
    );
}

export default Admin;