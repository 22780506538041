// src/NavBar.js
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import logo from '../Assets/logo.png'
import "../App.css"
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";

function NavBar(props) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out the currently authenticated user
      // You can perform additional actions after successful logout, e.g., navigate to a different page.
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  }



  return (
    <div className='navbar'>
      <img alt="logo" src={logo} className='navbar-logo' />
      <div className='navbar-container'>
      {props.backAdminButton && (
        <Button 
        style={{backgroundColor:"#dd9933", color:"white"}} 
        onClick={() => navigate('/admin')} 
        className='navbar-button'>
          Back
        </Button>
      )}
      {props.backDashboardButton && (
        <Button 
        style={{backgroundColor:"#dd9933", color:"white"}} 
        onClick={() => navigate('/dashboard')} 
        className='navbar-button'>
          Back
        </Button>
      )}
      {props.signOut && (
        <Button 
        style={{backgroundColor:"#dd9933", color:"white"}} 
        onClick={handleLogout} 
        className='navbar-button'>
          Logout
        </Button>
      )}

</div>
    </div>

  );
}

export default NavBar;