import React from "react";
import { AuthProvider } from './Components/AuthContext';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Change 'Switch' to 'Routes'
import Login from "./Pages/Login";
import LoginOnly from "./Pages/LoginOnly";
import Survey from "./Pages/Survey";
import Payment from "./Pages/Payment";
import Report from "./Pages/Report";
import Admin from "./Pages/Admin";
import Dashboard from "./Pages/Dashboard";
import AdminReport from "./Components/AdminReport";

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/loginOnly" element={<LoginOnly />} />
            <Route path="/" element={<Survey />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/payment/" element={<Payment />} />
            {/* below is needed to capture the return statusfrom Stripecheckout */}
            <Route path="/payment/:successParam" element={<Payment />} />
            <Route path="/survey/:id" element={<Survey />} />
            <Route path="/report/:id" element={<Report />} />
            <Route path="/report/:id/:uid" element={<Report />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/adminReport" element={<AdminReport />} />

          </Routes>
        </Router>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;