import React  from 'react'
import "../App.css";

const WelcomeText =()=>{
  return(
    <>
  <p className="login-page-intro-header">Welcome to your Career Mapping program</p>
  <p className="login-page-intro-text">The 3 simple steps below are an investment in your time and career that you will not regret!</p>
  <p className="login-page-intro-text"><u>Step 1</u> - Register and start the self-paced occupation assessment</p>
  <p className="login-page-intro-text"><u>Step 2</u> - Once your purchase is complete and you have completed the self-paced assessment, you will receive your results for review so that you can begin your journey.</p>
  <p className="login-page-intro-text"><u>Step 3</u> (if purchased) - You can schedule your initial consultation and coaching session with a certified coach</p>

  </>
  )
}
  export default WelcomeText
    ;