import React, { useState, useEffect } from 'react';
import { database } from '../firebase';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../Components/AuthContext';
import { Card } from 'semantic-ui-react'

import CreateUserSurveyQuestions from '../Components/Dashboard_CreateUserSureveyQuestions';
import ReportCard from '../Components/Dashboard-ReportCard';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import AppVariables from './AppVariables'
import '../App.css';

function Dashboard() {
  const [userData, setUserData] = useState({RegistrationInfo:{payment:null}});

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if (!user) {
      // User is not authenticated, navigate to the login page
      console.log("no user");
      navigate('/login');
      return;
    }
    console.log("user", user);
    const userRef = database.ref('/users/' + user.uid);
    console.log("user ref", userRef);

    // Fetch questions from the database or call CreateUserSurveyQuestions if needed
    userRef.on('value', async (snapshot) => {
      const data = snapshot.val();
      // console.log("user details", data.RegistrationInfo.accoutType);

      if (data && data.RegistrationInfo.accountType === "admin") {
        console.log("Admin User")
        navigate('/admin');
      }

      if (data && data.RegistrationInfo.payment==="TBD") {
        console.log("user needs to pay first")
        navigate('/payment');
      }

      setUserData(data);
    });
  }, []);

  var keys = Object.keys(userData)
  const numberOfReports = keys.filter(key => key.startsWith('report')).length;

  const handleViewReport = (name)=>{
    navigate(`/report/${name}`);
  }

  const handleRestartSurvey = async(name)=>{
    const newQuestions = await CreateUserSurveyQuestions(user.uid, name);
    navigate(`/survey/${name}`);
  }

  const handleContinueSurvey = (name)=>{
    navigate(`/survey/${name}`);
  }

  const handleStartSurvey = async (name)=>{
    const newQuestions = await CreateUserSurveyQuestions(user.uid, name);
    navigate(`/survey/${name}`);
  }


  return (
    <div>
    <Navbar signOut={true}/>
    <div className='payment-screen'>
      <Card.Group centered>

      <ReportCard 
      name="1" 
      userData={userData}
      handleViewReport={handleViewReport}
      handleRestartSurvey={handleRestartSurvey}
      handleContinueSurvey={handleContinueSurvey}
      handleStartSurvey={handleStartSurvey}
      />
      <ReportCard 
      name="2" 
      userData={userData}
      handleViewReport={handleViewReport}
      handleRestartSurvey={handleRestartSurvey}
      handleContinueSurvey={handleContinueSurvey}
      handleStartSurvey={handleStartSurvey}
      disabled={numberOfReports < 1}
      />
      <ReportCard 
      name="3" 
      userData={userData}
      handleViewReport={handleViewReport}
      handleRestartSurvey={handleRestartSurvey}
      handleContinueSurvey={handleContinueSurvey}
      handleStartSurvey={handleStartSurvey}
      disabled={numberOfReports < 2}
      />

      {userData.RegistrationInfo.payment==="premium" && 
      <ReportCard 
      name="4" 
      userData={userData}
      handleViewReport={handleViewReport}
      handleRestartSurvey={handleRestartSurvey}
      handleContinueSurvey={handleContinueSurvey}
      handleStartSurvey={handleStartSurvey}    
      disabled={numberOfReports < 3} 
      />}
      {userData.RegistrationInfo.payment==="premium" && 
      <ReportCard 
      name="5" 
      userData={userData}
      handleViewReport={handleViewReport}
      handleRestartSurvey={handleRestartSurvey}
      handleContinueSurvey={handleContinueSurvey}
      handleStartSurvey={handleStartSurvey}
      disabled={numberOfReports < 4}   
      />}
      </Card.Group>

      </div>
    <Footer/>

    </div>
  );
}

export default Dashboard