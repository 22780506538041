const AppVariables = {
    products:{
        "basic":{
            name:"Basic Package",
            priceID:"price_1MxQ5hFJX9g7JwR9yqLwJb2q",
            id:"basic",
            reportCount:3,
            price:"50.00 ($100 Value)",
            description: (                
            <div>
                <ul>
                  <li> Individual access to the occupation assessment (Can take the assessment up to 3 times max)</li>
                  <li>Unlimited access to printable reports</li>
                </ul>
              </div>) 
        },
        "plus":{
            name:"Plus Package",
            priceID:"price_1MxQ5hFJX9g7JwR9yqLwJb2q",
            id:"plus",
            reportCount:3,
            price:"100.00 ($350 Value)",
            description:(
                <div>
                <ul>
                  <li>Individual access to the occupation assessment (Can take the assessment up to 3 times max)</li>
                  <li>Unlimited access to printable reports</li>
                  <li>1 hour of coaching from a certified life and career coach ... <u>for 1 month</u></li>
                </ul>
              </div>
            )
            
        },
        "premium":{
            name:"Premium Package",
            priceID:"price_1MxQ5hFJX9g7JwR9yqLwJb2q",
            id:"premium",
            reportCount:5,
            price:"150.00 ($850 Value)",
            description:(
                <div>
                <ul>
                  <li>Individual access to the occupation assessment (Can take the assessment up to 5 times max)</li>
                  <li>Unlimited access to printable reports</li>
                  <li>1 hour per month of coaching from a certified life and career coach ... <u>for 3 month</u></li>
                </ul>
              </div>
            )
        }
    },

    color:{
        primary:"#4d5bce",
        secondary:"#4d5bce",
    }
    
}

export default AppVariables