import React, { useState, useEffect } from 'react';
import { Table, Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const SortableTable = ({ data}) => {
  const [sortedData, setSortedData] = useState([...Object.keys(data)]);
  const navigate = useNavigate();


  useEffect(() => {
    // Convert the object keys and values to an array of objects
    const dataArray = Object.entries(data).map(([key, value]) => ({ key, value }));
  
    // Sort the array based on the RegistrationInfo.registrationDate property
    const sortedArray = dataArray.sort((a, b) => {
      // Assuming date is in ISO string format, you can parse it to compare
      const dateA = new Date(a.value.RegistrationInfo.registrationDate);
      const dateB = new Date(b.value.RegistrationInfo.registrationDate);
  
      // Compare dates in descending order (newest to oldest)
      return dateB - dateA;
    });
  
    // Update sortedData with the sorted array of keys
    setSortedData(sortedArray);
  }, [data]);
  // const numberOfReports = keys.filter(key => key.startsWith('report')).length;

  return (
    <Table  celled fixed style={{backgroundColor:"#344f45", color:"white"}}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell >
            Account Type
          </Table.HeaderCell>
          <Table.HeaderCell >
            Email
          </Table.HeaderCell>
          <Table.HeaderCell >
            First Name
          </Table.HeaderCell>
          <Table.HeaderCell >
            Last Name
          </Table.HeaderCell>
          <Table.HeaderCell >
            Package Type
          </Table.HeaderCell>
          <Table.HeaderCell>
            Registration Date
          </Table.HeaderCell>
          <Table.HeaderCell  >
            Reports
          </Table.HeaderCell>

        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedData.map((record) => (

          <Table.Row key={record.value.RegistrationInfo.email}>
            <Table.Cell>{record.value.RegistrationInfo.accountType}</Table.Cell>
            <Table.Cell>{record.value.RegistrationInfo.email}</Table.Cell>
            <Table.Cell>{record.value.RegistrationInfo.firstName}</Table.Cell>
            <Table.Cell>{record.value.RegistrationInfo.lastName}</Table.Cell>
            <Table.Cell>{record.value.RegistrationInfo.payment}</Table.Cell>
            <Table.Cell>{record.value.RegistrationInfo.registrationDate}</Table.Cell>
            {record.value.RegistrationInfo.accountType ==="admin" || record.value.RegistrationInfo.payment==="TBD" ? null :
              <Table.Cell>
             
                <a
                  onClick={() => navigate(`/report/1/${record.key}`)}
                  style={{ color:record.value.report1? "#dd9933": 'white',
                  pointerEvents: record.value.report1? 'auto' : 'none',
                  cursor: record.value.report1? 'pointer' : "none",
                   marginRight:"10px"}}>
                  1
                </a>

                <a
                  onClick={() => navigate(`/report/2/${record.key}`)}
                  style={{ color:record.value.report2? "#dd9933": 'white',
                  pointerEvents: record.value.report2? 'auto' : 'none',
                  cursor: record.value.report2? 'pointer' : "none",
                   marginRight:"10px"}}>
                  2
                </a>

                <a
                  onClick={() => navigate(`/report/3/${record.key}`)}
                  style={{ color:record.value.report3? "#dd9933": 'white',
                  pointerEvents: record.value.report3? 'auto' : 'none',
                  cursor: record.value.report3? 'pointer' : "none",
                   marginRight:"10px"}}>
                  3
                </a>
                
                {record.value.RegistrationInfo.payment==="premium" &&
                <a
                onClick={() => navigate(`/report/4/${record.key}`)}
                  style={{color:record.value.report4? "#dd9933": 'white',
                  pointerEvents: record.value.report4? 'auto' : 'none',
                  cursor: record.value.report4? 'pointer' : "none",
                   marginRight:"10px"}}>
                  4
                </a>
                }

                {record.value.RegistrationInfo.payment==="premium" &&
                <a
                  onClick={() => navigate(`/report/1/${record.key}`)}
                  style={{ color:record.value.report5? "#dd9933": 'white',
                  pointerEvents: record.value.report5? 'auto' : 'none',
                  cursor: record.value.report5? 'pointer' : "none",
                   marginRight:"10px"}}>
                  5
                </a>
                } 

              </Table.Cell> 
          }
          </Table.Row>
         )
        )} 
      </Table.Body>
    </Table>
  );
};

export default SortableTable;
