
import { database } from '../firebase';

const CreateUserSurveyQuestions = async (userId, setName) => {
  try {
    const questionsRef = database.ref('questions');
    const snapshot = await questionsRef.once('value');
    const questionsData = snapshot.val();
    // console.log("questionsData", questionsData);

    if (questionsData) {
      // Create a new entry under the "user" node
      const userRef = database.ref(`users/${userId}/responses${setName}`);
      await userRef.set(questionsData);

      return new Promise((resolve, reject) => {
        userRef.on('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            // console.log('This is the list of questions that should be returned:', Object.values(data));
            resolve(Object.values(data));
          } else {
            console.log('No data found in the "questions" node.');
            resolve([]);
          }
        });
      });
    } else {
      console.log('No data found in the "questions" node.');
      return [];
    }
  } catch (error) {
    console.error('Error copying questions to user:', error);
    throw error;
  }
};

export default CreateUserSurveyQuestions;
