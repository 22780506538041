import React, { useState } from 'react'
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Input, Container } from 'semantic-ui-react'
import "../App.css";

const LoginSection = (props) => {
  const [loginDetails, setLoginDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setErrorMessage(null); // Reset error message before attempting login.
      if (!isEmailValid(loginDetails.email)) {
        setErrorMessage('Invalid email format. Please enter a valid email address.');
        return;
      }
      if (loginDetails.password.length < 8 || !loginDetails.password) {
        setErrorMessage('Password should be at least 8 characters long.');
        return
      }
      await auth.signInWithEmailAndPassword(loginDetails.email, loginDetails.password);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      setErrorMessage("Incorrect username or password."); // Set the error message.
    }
  };

  const isEmailValid = (email) => {
    // Basic email format validation using a regular expression
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  return (
    <Grid.Row className="login-page-inputs-containers">

      <Grid.Row>
        <Input
          className="login-page-inputs"
          type="email"
          placeholder="Email"
          value={loginDetails.email}
          onChange={(e) => setLoginDetails({ ...loginDetails, email: e.target.value })}
        />
      </Grid.Row>

      <Grid.Row>
        <Input
          className="login-page-inputs"
          type="password"
          placeholder="Password"
          value={loginDetails.password}
          onChange={(e) => setLoginDetails({ ...loginDetails, password: e.target.value})}
        />
        {errorMessage && <p className="login-page-error">{errorMessage}</p>} {/* Display wrong password msg*/}
      </Grid.Row>
      <Grid.Row className="login-page-buttons">
        <Container>
          <p>
            <span onClick={() => props.setOpenForgotPasswordModal(true)} className="link">
              Forgot Password
            </span>
          </p>
        </Container>
        <Button
          style={{ backgroundColor: "#dd9933", color: "white" }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Grid.Row>
    </Grid.Row>
  )
}

export default LoginSection
  ;